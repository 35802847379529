<template>
  <div>
    <banner bold-title="Información"
            type="banner--admin"
            title=" de la Reserva">
    </banner>
    <div class="row px-7">
      <div class="col-12 d-flex justify-end">
        <v-btn
          v-if="currentStatus === 'new'"
          class=" elevation-0 text-none"
          v-bind="attrs"
          v-on="on"
          @click="approve()"
          color="secondary"
        >
          <v-icon
            center
            dark
            small
            class="mr-2"
          >
            fa fa-check
          </v-icon>
          Aprobar
        </v-btn>
        <v-btn
          color="primary"
          class="white--text text-none elevation-0 ml-2"
          @click="back"
          dark
        >
          Regresar
        </v-btn>
      </div>
    </div>
    <v-stepper class="elevation-0" v-model="step" vertical non-linear>
      <v-stepper-step step="0" complete editable>
        Stands reservados
      </v-stepper-step>

      <v-stepper-content step="0">
        <booking-stands
          :booking-id="bookingId"
          @nextStep="nextStep"
        ></booking-stands>
      </v-stepper-content>

      <v-stepper-step complete step="1" editable>
        Información de la solicitud de stands
      </v-stepper-step>

      <v-stepper-content step="1">

        <booking-info
          ref="bookingInfo"
          :booking-id="bookingId"
          @nextStep="nextStep"
        ></booking-info>
      </v-stepper-content>

      <v-stepper-step complete step="2" editable>
        Otros contactos de interés de la feria
      </v-stepper-step>

      <v-stepper-content step="2">

        <other-interest-contacts
          ref="otherInterestContacts"
          :booking-id="bookingId"
          @nextStep="nextStep"
        ></other-interest-contacts>

      </v-stepper-content>

      <v-stepper-step complete step="3" editable>
        Información adicional de la empresa
      </v-stepper-step>

      <v-stepper-content step="3">

        <additional-company-info
          ref="additionalCompanyInfo"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="4" editable>
        Actividad económica
      </v-stepper-step>

      <v-stepper-content step="4">

        <economic-activity
          ref="economicActivity"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="5" editable>
        Reseña de la empresa
      </v-stepper-step>

      <v-stepper-content step="5">

        <company-description
          ref="companyReview"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="6" editable>
        Datos para el envío de la factura
      </v-stepper-step>

      <v-stepper-content step="6">

        <invoice-data
          ref="invoiceData"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="7" editable>
        Resumen de la reserva
      </v-stepper-step>

      <v-stepper-content step="7">

        <booking-summary
          ref="bookingSummary"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="8" editable>
        Información comercial
      </v-stepper-step>

      <v-stepper-content step="8">

        <commercial-info
          ref="commercialInfo"
          :booking-id="bookingId"
          :fair="fair"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="9" editable>
        Empresas que representan en Colombia y perfil de su cliente potencial
      </v-stepper-step>

      <v-stepper-content step="9">

        <represented-companies
          ref="commercialInfo"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="10" editable>
        Empresas de interés
      </v-stepper-step>

      <v-stepper-content step="10">

        <target-companies
          ref="interestCompanies"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />

      </v-stepper-content>

      <v-stepper-step complete step="11" editable>
        Participación en ruedas de negocios
      </v-stepper-step>

      <v-stepper-content step="11">

        <participation-business-conference
          ref="ParticipationBusinessConference"
          :booking-id="bookingId"
          @nextStep="nextStep"
        />
      </v-stepper-content>
    </v-stepper>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import BookingInfo from '@/views/private/booking-confirmation/components/BookingInfo'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import OtherInterestContacts from '@/views/private/booking-confirmation/components/OtherInterestContacts'
import AdditionalCompanyInfo from '@/views/private/booking-confirmation/components/AdditionalCompanyInfo'
import CompanyDescription from '@/views/private/booking-confirmation/components/CompanyDescription'
import EconomicActivity from '@/views/private/booking-confirmation/components/EconomicActivity'
import InvoiceData from '@/views/private/booking-confirmation/components/InvoiceData'
import BookingSummary from '@/views/private/booking-confirmation/components/BookingSummary'
import CommercialInfo from '@/views/private/booking-confirmation/components/CommercialInfo'
import TargetCompanies from '@/views/private/booking-confirmation/components/TargetCompanies'
import RepresentedCompanies from '@/views/private/booking-confirmation/components/RepresentedCompanies'
import ParticipationBusinessConference
  from '@/views/private/booking-confirmation/components/ParticipationBusinessConference'
import Banner from '@/components/banner/Banner'
import BookingStands from '@/views/private/booking-confirmation/components/BookingStands'
import { BOOKINGS_URL, FAIRS_URL } from '@/constants/ServicesConstants'

export default {
  name: 'BookingEdition',
  async created () {
    this.bookingId = this.$route.params.id
    this.fair.id = this.$route.params.fairId
    this.step = 0
    await this.getFair()
    await this.getCurrentStatus()
  },
  data () {
    return {
      step: -1,
      bookingId: null,
      fair: {
        id: null,
        name: '',
        year: null
      },
      currentStatus: null
    }
  },
  methods: {
    async getCurrentStatus () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/current-status')
      this.currentStatus = response.data
    },
    async approve (item) {
      try {
        this.showLoader()
        await this.post(BOOKINGS_URL + '/' + this.bookingId + '/approve')
        this.hideLoader()
        this.search()
        this.showSuccess('La reserva fue aprobada correctamente')
      } catch (error) {
        this.hideLoader()
        this.showError('Error al aprobar la reserva')
      }
    },
    back () {
      this.$router.push({
        name: 'bookings'
      })
    },
    nextStep (step) {
      this.step = step
    },
    async getFair () {
      if (this.fair.id) {
        const response = await this.get(`${FAIRS_URL}/${this.fair.id}`)
        this.fair = response.data
      }
    }
  },
  watch: {
    step: (newValue) => {
      eventHub.$emit('step' + newValue)
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin,
    formOperationsMixin
  ],
  components: {
    BookingStands,
    Banner,
    TargetCompanies,
    CommercialInfo,
    EconomicActivity,
    CompanyDescription,
    AdditionalCompanyInfo,
    OtherInterestContacts,
    BookingInfo,
    InvoiceData,
    BookingSummary,
    RepresentedCompanies,
    ParticipationBusinessConference
  }
}
</script>
