<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-end">
        <v-btn color="secondary" class="text-none elevation-0" @click="addStands">
          Agregar
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Espacio
              </th>
              <th class="text-left">
                Código
              </th>
              <th class="text-left">
                Metros cuadrados
              </th>
              <th class="text-left">
                Total valor en COP (IVA incluido)
              </th>
              <th>
                Total valor en USD (IVA incluido)
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(stand,index) in stands" :key="stand.id">
              <td>{{ stand.placeName }}</td>
              <td>{{ stand.code.toUpperCase() }}</td>
              <td>{{ stand.area }}</td>
              <td>${{ stand.copValue + stand.copTax | currency }}</td>
              <td>{{ stand.usdValue + stand.usdTax | currency }} USD</td>
              <td>
                <v-btn @click="onRemove(index)" text>
                  eliminar
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <v-btn color="primary" class="mr-2 text-none elevation-0" @click="saveStep0">
          <span v-if="!hasPermission('EDIT_BOOKING')">Siguiente</span>
          <span v-if="hasPermission('EDIT_BOOKING')">Guardar</span>
        </v-btn>
      </div>
    </div>
    <free-stands-modal ref="freeStandsModal" @onSelectStands="selectStands"></free-stands-modal>
  </div>
</template>

<script>
import { BOOKINGS_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import eventHub from '@/eventhub'
import sessionMixin from '@/mixins/sessionMixin'
import FreeStandsModal from '@/views/private/booking-confirmation/components/FreeStandsModal'

export default {
  name: 'BookingStands',
  components: { FreeStandsModal },
  props: {
    bookingId: {
      default: null,
      type: String
    }
  },
  async created () {
    eventHub.$on('step0', async () => {
      try {
        this.showLoader()
        await this.getStep0()
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    })
  },
  methods: {
    addStands () {
      this.$refs.freeStandsModal.open(this.bookingId, this.stands)
    },
    selectStands (selectedStands) {
      this.stands = this.stands.concat(selectedStands)
    },
    async getStep0 () {
      const response = await this.get(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-0')
      this.stands = response.data
    },
    saveStep0 () {
      this.create(BOOKINGS_URL + '/' + this.bookingId + '/confirm-step-0', { bookingStands: this.stands })
        .then(() => {
          this.$emit('nextStep', 1)
        })
    },
    close () {
      this.isCompanyDialogActive = false
    },
    async add (model) {
      this.isCompanyDialogActive = false
      this.stands.push(model)
    },
    onRemove (index) {
      this.stands.splice(index, 1)
    }
  },
  mixins: [crudServiceMixin, loaderMixin, notificationMixin, formOperationsMixin, sessionMixin],
  data: function () {
    return {
      isCompanyDialogActive: false,
      stands: []
    }
  }
}
</script>
